import './style.css';

function App() {
  return (
    <div className="App">
      <div className="left">
        <div className='left-center'>
          <div className='card'>
              <div className="img"></div>
              <h2>N. Abdulaziz</h2>
              <h3>WEB DEVELOPER</h3>
              <div className="links">
                <a href="#"><div className="link"></div></a>
                <a href="#"><div className="link"></div></a>
                <a href="#"><div className="link"></div></a>
                <a href="#"><div className="link"></div></a>
              </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className='card'>
          <h1>HELLO<span>!</span></h1>
          <h2>I'm Abdulaziz, a developer and designer</h2>
          <div className="btns">
            <button className='btn1'>RESUME / CV</button>
            <button className='btn2'>GIT HUB</button>
          </div>
          <div className="contact">
            <h3>Contact</h3>
            <h4>Email:</h4>
            <a href="mailto:shaxsiy@internet.ru">shaxsiy@internet.ru</a>
            <h4>Mobile</h4>
            <a href="tel:+9989444****8">+998 94 44* ** *8</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
